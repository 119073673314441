module.exports = {
	title: 'Lokal Tank, Резервуари та металеві конструкції',
	legalName: 'Lokal Tank Sp. Z o.o.',
	description: 'Компанія LOCAL TANK спеціалізується на виробництві високоякісних резервуарів під тиском, резервуарів для стаціонарного зберігання, та виробництвом металевих конструкцій.',
	address: {
		city: 'Chorzów',
		country: 'Польща'
	},
	keyWords: 'LokalTank, Lokal Tank, напірні, накопичувальні резервуари, металоконструкції',
	contact: {
		email: 'biuro@lokaltank.pl',
		phone: '+48 517 580 300'
	},
	socialLinks: {
		// linkedin: 'https://www.linkedin.com/in/ismail-ghallou-630149122/',
	},
}
