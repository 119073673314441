module.exports = {
	title: 'Lokal Tank, Pressure filters',
	logo: 'https://lokaltank.pl/favicon/logo-48.png',
	url: 'https://lokaltank.pl',
	favicon: 'https://lokaltank.pl/favicon/logo-48.png',
	cover: 'https://lokaltank.pl/cover.jpg',
	legalName: 'Lokal Tank',
	description: 'LOCAL TANK is a company, which specializing in the production of high-quality pressure and storage vessels, steel constructions',
	socialLinks: {
		// linkedin: 'https://www.linkedin.com/in/ismail-ghallou-630149122/',
	},
	googleAnalyticsID: '',
	themeColor: '#000696',
	backgroundColor: '#6496f6',
	address: {
		city: 'Chorzów',
		country: 'Poland',
		zipCode: '41-530'
	},
	contact: {
		email: 'biuro@lokaltank.pl',
		phone: '+48 517 580 300'
	},
	foundingDate: '2023',
	googleVerification: 'ikc1n6wGjGhiBkfHs1HbodNOINf2nnbs_0HX68LcFAo',
	keyWords: 'LokalTank, Lokal Tank, Tank, pressure, storage tanks, steel structures'
}
